
import './App.css';
import { useEffect, useState } from 'react';
import Unity, { UnityContext } from "react-unity-webgl";
import {
  getCurrentWalletConnected, getBalanceMewa,
  getBalanceSOG, mintNFTs, tokenTransfer, claimToken, burnHeroes,
  signIn,
  SetCurtAccount, GetCurtAccount
} from "./utils/interact";


const unityContext = new UnityContext({
  loaderUrl: "unity/Build/Build.loader.js",
  dataUrl: "unity/Build/Build.data.unityweb",
  frameworkUrl: "unity/Build/Build.framework.js.unityweb",
  codeUrl: "unity/Build/Build.wasm.unityweb",
  streamingAssetsUrl: "unity/StreamingAssets",
});

export const SendAddressToGame = (_address) => {
  console.log(_address);
  unityContext.send("WebGLListener", "ListenerAddress", _address);
};

function App() {
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [progression, setProgression] = useState(0);


  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        console.log("----- on Here ! -----");
        let currentAccount = GetCurtAccount();
        if (accounts.length > 0) {

          if (accounts[0] !== currentAccount) {
            currentAccount = accounts[0];
            SetCurtAccount(accounts[0]);
          }
          setWallet(accounts[0]);

          DisconnectGame();
          //window.location.reload(false);
        } else {
          currentAccount = null;
          setWallet("");
          SetCurtAccount(null);
        }
      });
      //let currentChainId = await window.ethereum.request({ method: 'eth_chainId' });
      window.ethereum.on('chainChanged', (chainId) => {
        const targetChainId = process.env.REACT_APP_TARGET_CHAIN_ID;
        // Handle the new chain.
        // Correctly handling chain changes can be complicated.
        // We recommend reloading the page unless you have good reason not to.
        if (chainId != targetChainId) {
          alert("Please switch to BSC Testnet");
        }
        DisconnectGame();
        //window.location.reload();
      });

    } else {
      alert("You must install Metamask, a virtual Ethereum wallet, in your browser.");
      window.open("https://metamask.io/download");
    }
  }

  const connectWalletPressed = async () => { //TODO: implement
    if (window.ethereum) {
      signIn();

      //  if (window.ethereum.networkVersion == parseInt(process.env.REACT_APP_CHAIN_ID)) {

      //     const walletResponse = await connectWallet();
      //     if (walletResponse.status == "") {
      //       setWallet(walletResponse.address);
      //       if (walletResponse.address != "") {
      //         SendAddressToGame(walletResponse.address);
      //       }
      //     } else if (walletResponse.status == "err_0") {
      //       alert("Please install MetaMask!");
      //       window.open("https://metamask.io/download");
      //     }
      //     else {
      //       alert(walletResponse.status);
      //     }
      //  } else {
      //     // alert("Please switch to BSC Testnet");
      //    switchToBSCTestnet();
      //   }
    }
  };

  //#################### Function Call to Unity #########################

  function DisconnectGame() {
    unityContext.send("WebGLListener", "DisconnectGame");
  }


  function EventMintNFts(_data) {
    unityContext.send("CanvasSummon", "EventMintNFts", _data);
  }

  function EventRejectNFT() {
    unityContext.send("CanvasSummon", "RejectMintNFT");
  }

  function EventClaimToken(_txHash) {
    unityContext.send("WebGLListener", "ListenerClaim", _txHash);
  }

  function EventTransfer(_data) {
    unityContext.send("WebGLListener", "ListenerTransfer", _data);
  }

  function LogUtil(_message) {
    unityContext.send("WebGLListener", "ErrorMessage", _message);
  }

  function EventRejectAscension() {
    unityContext.send("WebGLListener", "RejectAscension");
  }

  function EventAscension(_txHash) {
    unityContext.send("WebGLListener", "EventAscension", _txHash)
  }
  //################## Event listeners from Unity #################


  useEffect(async () => { //TODO: implement
    const { address, status } = await getCurrentWalletConnected();
    setWallet(address);
    setStatus(status);
    addWalletListener();

    unityContext.on("ConnectMetamask", function () {
      connectWalletPressed();
    });

    // summon 10 hero
    unityContext.on("MintNfts", function (_lstHeroID) {
      onMintNfts(_lstHeroID);
    });

    unityContext.on("Claim", function (_data) {
      onClaimToken(_data);
    });

    unityContext.on("TransferToken", function (_data) {
      const jsonDt = JSON.parse(_data);
      const value = jsonDt[0];
      const tkName = jsonDt[1];
      onTransferToken(value, tkName);
    });

    unityContext.on("AscensionHero", function (_lstTokenId) {
      onAscensionHero(_lstTokenId);
    });

    unityContext.on("progress", function (progression) {
      setProgression(progression);
    });
  }, []);
  //################## ################# #################


  const onMintNfts = async (m_listHeroId) => {
    if (m_listHeroId != "") {
      const { transactionHash, tokens, success } = await mintNFTs(walletAddress, m_listHeroId);
      if (success) {
        const dt = new Object();
        dt.txHash = transactionHash;
        dt.lsToken = tokens;
        const data = JSON.stringify(dt);
        console.log(data);
        EventMintNFts(data);
      } else {
        if (transactionHash) {
          if (transactionHash.code == "4001") {
            EventRejectNFT();
          } else {
            EventMintNFts("");
          }
          LogUtil(transactionHash.message);
          //  alert(transactionHash.code + ": " + transactionHash.message);
        }
      }
    } else {
      console.log("List heroes null");
      LogUtil("List heroes null");
    }
  };

  const onClaimToken = async (m_data) => {
    console.log("m_data: " + m_data);
    // m_signature = "0x4f5dcc05aff47cf06fa09c12c78fed2f31046c54ba5683989274119ee1625a4574f904529b936553c862f35adfa7d83cf42130cbe82e01cd38fc52be044881061b";
    if (m_data) {
      const { success, txHash } = await claimToken(m_data);
      console.log(txHash);
      if (success) {
        if (txHash != "") {
          EventClaimToken(txHash);
        }
      } else {
        if (txHash) {
          if (txHash.code == "4001") {

          } else {

          }
          // alert(txHash.code + ": " + txHash.message);
          LogUtil(txHash.message);
        }
      }

    } else {
      console.log("__ Signature not found ___");
      LogUtil("Signature not found");
    }
  };

  const onTransferToken = async (m_value, m_tkName) => {
    // m_value = "1000";
    // m_tkName = "SOG";
    var balanceOf;
    if (m_tkName == "SOG") {
      balanceOf = await getBalanceSOG();
    }
    else if (m_tkName == "MEWA") {
      balanceOf = await getBalanceMewa();
    }
    console.log(balanceOf);
    if (parseInt(balanceOf) >= parseInt(m_value)) {
      const { success, hash } = await tokenTransfer(m_value, m_tkName);
      if (success) {
        if (hash != "") {
          const data = {
            txHash: hash,
            value: m_value,
            nToken: m_tkName
          }
          EventTransfer(JSON.stringify(data));
        }
      } else {
        if (hash) {
          if (hash.code == "4001") {

          } else {

          }
          LogUtil(hash.message);
          // alert(hash.code + ": " + hash.message);
        }
      }
    } else {
      console.log("Not Enough Token");
      LogUtil("Not Enough Token");
    }

  };

  const onAscensionHero = async (_lstTokenId) => {
    const response = await burnHeroes(_lstTokenId);
    if (response.success) {
      //const data = JSON.stringify(dt);
      //console.log(data);
      EventAscension(response.hash);
    } else {
      if (response.hash) {
        if (response.hash.code == "4001") {
          EventRejectAscension();
        } else {
          EventAscension("");
        }
        LogUtil(response.hash.message);
      }
    }
  }


  return (

    <div className="App">
      <div class="webgl-wrapper">
        <div class="aspect"></div>
        <div class="webgl-content">
          <div id="unityContainer">
            {progression < 1 ? (<p>Loading {progression * 100} percent...</p>) : (<br></br>)}
            <Unity unityContext={unityContext} devicePixelRatio={2} style={{ background: "#231F20" }} />
            {/* <canvas id="unity-canvas" style="background: #231F20"></canvas> */}
          </div>
        </div>
      </div>
      <br></br>
      <br></br>
      <br></br>
      {/* <button id="mintButton" onClick={onBurnHeroes}>
        Test
      </button> */}
    </div>
  );
}

export default App;
